<template>
  <div class="height_100">
    <el-container class="height_100">
      <el-aside class="left" style="background-color: #2e3751">
        <el-menu
          @open="handleOpen"
          @close="handleClose"
          @select="handleSelect"
          class="el-menu-vertical-demo"
          background-color="#2e3751"
          text-color="#fff"
          active-text-color="#ff6618"
          :default-active="activeIndex"
        >
          <el-menu-item class="menu-item" @click="goHome">
            <span slot="title">网站首页</span>
          </el-menu-item>

          <el-menu-item
            class="menu-item"
            @click="goSpecialistInformation"
            index="1"
          >
            <i class="el-icon-menu"></i>
            <span slot="title" style="font-size: 20px">服务专员信息</span>
          </el-menu-item>
          <el-menu-item class="menu-item" @click="gospecialistappeal" index="2">
            <i class="el-icon-menu"></i>
            <span slot="title" style="font-size: 20px">诉求管理</span>
          </el-menu-item>
          <el-menu-item class="menu-item" @click="gospecialistorder" index="3">
            <i class="el-icon-menu"></i>
            <span slot="title" style="font-size: 20px">订单管理</span>
          </el-menu-item>
          <el-menu-item class="menu-item" @click="gofeedback" index="4">
            <i class="el-icon-menu"></i>
            <span slot="title" style="font-size: 20px">入企服务月度反馈表</span>
          </el-menu-item>

          <el-menu-item class="menu-item" @click="goservemoney" index="5">
            <i class="el-icon-menu"></i>
            <span slot="title" style="font-size: 20px">服务币管理</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header
          style="
            height: 72px;
            line-height: 72px;
            border-bottom: 1px solid #e6e6e6;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #2e3751;
            color: #fff;
            font-size: 20px;
          "
        >
        青海省中小企业公共服务平台-服务专员个人中心

          <div style="display: flex; align-items: center">
            <i
              class="el-icon-user-solid"
              style="color: #fff; font-size: 20px"
            ></i>
            <div style="padding: 0 12px; color: #fff; font-size: 20px">
              {{ userphone }} <span style="margin-left: 10px">个人中心</span>
            </div>
            <div @click="login">退出</div>
          </div>
        </el-header>
        <el-main>
          <router-view v-if="this.$route.path != '/personalcenter'" />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>


<script>
export default {
  data() {
    return {
      userphone: localStorage.phone,
      activeIndex: "1",
    };
  },
  created() {
    this.goSpecialistInformation();
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, "keyhandleSelect");
      console.log(keyPath, "keyPathhandleSelect");
      if (key == "1") {
        this.$router.push("/information");
      } else if (key == "2") {
        this.$router.push("/serviceAction");
      } else if (key == "3") {
        this.$router.push("/nextAction");
      }
    },

    handleOpen(key, keyPath) {
      console.log(key, keyPath, "handleOpen");
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath, "handleClose");
    },
    goSpecialistInformation() {
      this.$router.push("/specialistInformation");
    },
    goservemoney() {
      this.$router.push("/servemoney");
    },
    gospecialistappeal() {
      this.$router.push("/specialistappeal");
    },
    gospecialistorder() {
      this.$router.push("/specialistorder");
    },
    gofeedback() {
      this.$router.push("/feedback");
    },
    goHome() {
      this.$router.push("/");
    },
    login() {
      this.$confirm("是否退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$get("/logout?token=" + this.token).then((res) => {
            localStorage.clear();
            // location.reload();
            this.$message({
              type: "success",
              message: "已退出",
            });
            this.$router.push("/");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  padding-left: 20px;
  color: rgb(255, 255, 255);
  background: rgb(46, 55, 81);
  text-align: center;
  font-size: 20px;
  height: 70px;
  line-height: 70px;
}
.menu_class {
  height: 60px;
  line-height: 60px;
}
.el-menu {
  border: transparent;
}
.menu-item {
  background: #262e45;
  text-align: left;
  font-size: 20px;
  height: 70px;
  line-height: 70px;
}
// /deep/.el-menu-item.is-active {
//   height: 60px;
//   color: #fff !important;
//   background-color: #ff6618 !important;
// }
::v-deep.el-menu-item.is-active {
  height: 70px;
  color: #fff !important;
  line-height: 70px;
  background-color: #ff6618 !important;
}
::v-deep .el-submenu {
  text-align: left;
}
</style>
